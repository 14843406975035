import React, { useEffect } from 'react'
import "./ProductDetails.css";
import Footer from '../../Organisms/Footer/FooterUpper';
import { getProductRequest } from "../../../redux/Actions/Product/getProductAction"
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

function ProductDetails() {
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProductRequest(id));
    }, [])

    const getProduct = useSelector((state) => state.getProduct);
    return (
        <div>
            <div className="appointmentmain">
                <h3 className="appId">Product Details -
                    {getProduct?.data?.data?.data?.data.id}
                </h3>
                <div className="appointment-box">
                        <p className="viewBox-head" style={{paddingTop:"1em"}}>
                            {getProduct?.data?.data?.data?.data.productName}
                        </p>
                    <hr />
                        <div className="productDetails" >
                            <div className='productDetailsMain'>
                                <div className='productDetailsLeft'>Brand Name</div>
                                <div className='productDetailsRight'><p style={{paddingTop:"1em"}}>{getProduct?.data?.data?.data?.data.brandName}</p></div>
                            </div>
                            <div className='productDetailsMain' >
                                <div className='productDetailsLeft productDetailsLeftDescription'>Short Description</div>
                                <div className='productDetailsRight'>{getProduct?.data?.data?.data?.data.shortDescription===""? "---" :<span style={{}} dangerouslySetInnerHTML={{__html: getProduct?.data?.data?.data?.data.shortDescription}} />}</div>
                            </div>
                            <div className='productDetailsMain' style={{marginTop:"1em"}}>
                                <div className='productDetailsLeft productDetailsLeftDescription'>Description</div>
                                <div className='productDetailsRight'>{getProduct?.data?.data?.data?.data.description===""?"---":<span style={{}} dangerouslySetInnerHTML={{__html: getProduct?.data?.data?.data?.data.description}} />}</div>
                            </div>
                            <div className='productDetailsMain'>
                                <div className='productDetailsLeft productDetailsLeftDescription'>Nutritional Value</div>
                                <div className='productDetailsRight'>{getProduct?.data?.data?.data?.data.nutritional===""?"---":<span style={{}} dangerouslySetInnerHTML={{__html: getProduct?.data?.data?.data?.data.nutritional}}/>} </div>
                            </div>
                            <div className='productDetailsMain'>
                                <div className='productDetailsLeft'>Category Name</div>
                                <div className='productDetailsRight'>{getProduct?.data?.data?.data?.data.categoryName===null? "---" : <p style={{paddingTop:"1em"}}>{getProduct?.data?.data?.data?.data.categoryName}</p>}</div>
                            </div>
                    
                            <div className='productDetailsMain' >
                                <div className='productDetailsLeft'>Sub-Category Name</div>
                                <div className='productDetailsRight'>{getProduct?.data?.data?.data?.data.subCategoryName===null? "---" : <p style={{paddingTop:"1em"}}>{getProduct?.data?.data?.data?.data.subCategoryName}</p>}</div>
                            </div>
                            

                        <div className='viewDetailImages' >
                            <p style={{ marginTop: "2em" }}>Product Images</p>
                            <div className='viewDetailImagesArea'>
                                {getProduct?.data?.data?.data?.data.productImageList.map((item) =>
                                (<img src={item.imageFilepath} style={{ width: "100px", height: "100px"}} />
                                ))}
                            </div>
                            <p style={{ marginTop: "2em" }}>Product Price</p>
                            <div className='viewDetailImagesArea'>
                                {getProduct?.data?.data?.data?.data.productPriceResponseDtoList.map((item) =>
                                (
                                    <div className='ViewProductDetailPricing'>
                                        <div className='ViewPlanDetailPricingLeft'>
                                            <p>Price</p>
                                            <p>Units</p>
                                        </div>
                                        <div className='ViewPlanDetailPricingRight'>
                                            <p>₹ {item.price} / -</p>
                                            <p> {item.unit} {item.productUnit}</p>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <Footer />
        </div>
    )
}

export default ProductDetails